import React from 'react';
import "./map.css"

const Map = () => {
    return (
        <>
            <div className="google-map ">
                <iframe
                    src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Gali%20No.%206,%20Sai%20Puram%20Ind.%20Area,%20Delhi%20Rd,%20Meerut,%20Uttar%20Pradesh%20+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} title="Contact Us Map" />
            </div>

        </>
    )
}

export default Map