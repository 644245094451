import React from 'react';
import "./fixedcontact.css";


const FixedContact = () => {
    return (
        <>
            <div id="whatsapp" className="whatsapp"><a href="https://wa.me/7455900612?text=" target="_blank" title="Whatsapp" data-bs-toggle="tooltip" data-bs-placement="top" rel="noreferrer"><i class="fab fa-whatsapp text-white"></i></a></div>
            <div id="callus" className="callus"><a href="tel: +91-7455900612" target="_blank" title="Whatsapp" data-bs-toggle="tooltip" data-bs-placement="top" rel="noreferrer"><i class="fas fa-phone text-white"></i></a></div>
        </>
    )
}




export default FixedContact