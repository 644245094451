import React from 'react'

const VideoComp = () => {
    return (
        <>
            <section class="block oneB offticker" >
                <video class="dVideo" autoPlay loop muted id="bgvid" width="100%" style={{ objectFit: "cover", maxHeight: "50vh" }}>
                    {/* <source src="https://digisidekick.com/naturavideo.mp4" type="video/mp4" /> */}
                    {/* <source src={require("../../assests/slider/rsfvideo.mp4")} type="video/mp4" /> */}
                    <source src={require("../../../assests/slider/rsfvideo2.mp4")} type="video/mp4" />
                </video>
            </section>
        </>
    )
}

export default VideoComp