import React from 'react'

const Loader = () => {
    return (
        <div className='row d-flex justify-content-center position-relative top-50 mt-5 ' style={{ height: "20vh" }}>
            <div className="col-md-8 text center position-absolute top-50 start-50">
                <div class="spinner-border text-dark " role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    )
}

export default Loader