import React from 'react';
import "./videocomp.css"

const VideoComp = () => {
    return (
        <section

            style={{  position: "relative" }}
        >
            <video className="videocomp" autoPlay loop muted id="bgvid">
                <source
                    src={require("../../../../assests/franchise/rsf.mp4")}
                    type="video/mp4"
                />
            </video>
        </section>
    )
}

export default VideoComp