import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Login from '../client/components/Login/Login';
import { axiosInstance } from '../config';
import Loader from './Loader';

const PrivateRoute = () => {


    const [user, setuser] = useState("")
    const [loading, setloading] = useState(false)



    useEffect(() => {
        getuser()
    }, [])




    const getuser = async () => {
        setloading(true)
        try {
            const { data } = await axiosInstance.get("/api/user/me", {
                headers: {
                    "token": localStorage.getItem("token")
                }
            })
            console.log("user", data)
            setuser(data)
            setloading(false)
            // isAdmin = data.isAdmin
            // console.log("isAdmin", isAdmin)


        } catch (error) {
            setloading(false)
            setuser("")

            // alert(error.response.data.message)
        }
    }


    // console.log("uccccser",isAdmin)
    // console.log("uccccser",user?.isAdmin)







    return (
        <>
            {
                loading ? <Loader /> :
                    user === "" ? <Login /> :
                        <>
                            {
                                user.isAdmin ? <Outlet /> : <Navigate to='/' />
                            }

                        </>
            }

        </>

    )
}

export default PrivateRoute