import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { Suspense, useEffect } from "react";

import PrivateRoute from "./routes/PrivateRoute";


import { getsliderdata, sliderdatafail, sliderdatasuccess } from "./redux/slice/sliderSlice";
import { useDispatch } from "react-redux";
import { axiosInstance } from "./config";
import MainLoader from "./client/components/mainloader/MainLoader";

// import FitnessTrainer from "./client/pages/fitness trainer/FitnessTrainer";


import Gym from "./client/components/gym/gymproduct/Gym";
// import Gymdetails from "./client/components/gym/gymdetails/Gymdetails";
// import ContactUs from "./client/contactus/ContactUs";


// import AdminCity from "./Admin/pages/city/AdminCity";
// import EditPlace from "./Admin/pages/place/EditPlace";
// import AdminAddPlace from "./Admin/pages/place/AdminAddPlace";
// import AdminAddCity from "./Admin/pages/city/AdminAddCity";
// import AdminEditCity from "./Admin/pages/city/AdminEditCity";
// import AdminPlace from "./Admin/pages/place/AdminPlace";
// import AdminGym from "./Admin/pages/AdminGym/AdminGym";
// import AdminAddGym from "./Admin/pages/AdminGym/AdminAddGym";
// import EditAdminGym from "./Admin/pages/AdminGym/EditAdminGym";
// import ServiceRequest from "./Admin/pages/serviceRequest/ServiceRequest";
// import Home from "./client/Home/Home";
// import AddBlog from "./Admin/pages/blog/AddBlog";
// import AdminBlog from "./Admin/pages/blog/AdminBlog";
// import BlogDetails from "./Admin/pages/blog/BlogDetails";
// import EditBlog from "./Admin/pages/blog/EditBlog";

import Academy from "./client/pages/academy/Academy";
import Franchise from "./client/pages/franchise/Franchise";





// const Gym = React.lazy(() => import("./client/components/gym/gymproduct/Gym"));
const FitnessTrainer = React.lazy(() => import("./client/pages/fitness trainer/FitnessTrainer"));
const Gymdetails = React.lazy(() => import("./client/components/gym/gymdetails/Gymdetails"));
const ContactUs = React.lazy(() => import("./client/contactus/ContactUs"));
const EditPlace = React.lazy(() => import("./Admin/pages/place/EditPlace"));
const AdminCity = React.lazy(() => import("./Admin/pages/city/AdminCity"));
const AdminAddCity = React.lazy(() => import("./Admin/pages/city/AdminAddCity"));
const AdminEditCity = React.lazy(() => import("./Admin/pages/city/AdminEditCity"));
const AdminGym = React.lazy(() => import("./Admin/pages/AdminGym/AdminGym"));
const AdminAddGym = React.lazy(() => import("./Admin/pages/AdminGym/AdminAddGym"));
const EditAdminGym = React.lazy(() => import("./Admin/pages/AdminGym/EditAdminGym"));
const ServiceRequest = React.lazy(() => import("./Admin/pages/serviceRequest/ServiceRequest"));
const AddBlog = React.lazy(() => import("./Admin/pages/blog/AddBlog"));
const AdminBlog = React.lazy(() => import("./Admin/pages/blog/AdminBlog"));
const EditBlog = React.lazy(() => import("./Admin/pages/blog/EditBlog"));
const AdminCategory = React.lazy(() => import("./Admin/pages/category/AdminCategory"));




// import AdminAddCategory from "./Admin/pages/category/AdminAddCategory";

const AdminAddCategory = React.lazy(() => import("./Admin/pages/category/AdminAddCategory"));


// import EditCategory from "./Admin/pages/category/EditCategory";


const EditCategory = React.lazy(() => import("./Admin/pages/category/EditCategory"));

// import Blog from "./client/components/blog/Blog";

const Blog = React.lazy(() => import("./client/components/blog/Blog"));


// import Blogdetails from "./client/components/blog/Blogdetails";
// import NewBlogList from "./client/components/blog/NewBlogList";

const NewBlogList = React.lazy(() => import("./client/components/blog/NewBlogList"));

// import MainCard from "./client/components/MainCard/MainCard";


// import About from "./client/components/about/About";

const About = React.lazy(() => import("./client/components/about/About"));
// import Login from "./client/components/Login/Login";

// const Franchise = React.lazy(() => import("./client/pages/franchise/Franchise"));
const Login = React.lazy(() => import("./client/components/Login/Login"));

// const Academy = React.lazy(() => import("./client/pages/academy/Academy"));




// import AdminClub from "./Admin/pages/club/AdminClub";
const AdminClub = React.lazy(() => import("./Admin/pages/club/AdminClub"));
// import AddClub from "./Admin/pages/club/AddClub";
const AddClub = React.lazy(() => import("./Admin/pages/club/AddClub"));
// import EditClub from "./Admin/pages/club/EditClub";
const EditClub = React.lazy(() => import("./Admin/pages/club/EditClub"));

// import AdminSlider from "./Admin/pages/slider/AdminSlider";
const AdminSlider = React.lazy(() => import("./Admin/pages/slider/AdminSlider"));

//Lazy loading component
const Home = React.lazy(() => import('./client/Home/Home'));
const ProductCategoryPage = React.lazy(() => import("./client/pages/product_category_page/ProductCategoryPage"));

// import Pagination from "./client/components/paginate/Pagination";
// import ProductCategoryPage from "./client/pages/product_category_page/ProductCategoryPage";

// ProductCategoryPage







function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    getslider()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])


  const getslider = async () => {
    try {
      dispatch(getsliderdata())
      const { data } = await axiosInstance.get("/api/slider");
      if (data.success) {
        dispatch(sliderdatasuccess(data.slider))
      }
    } catch (error) {
      dispatch(sliderdatafail())
    }
  }




  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<MainLoader/>}>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="/admin/gym" element={<AdminGym />} />
              <Route path="/admin/add-gym" element={<AdminAddGym />} />
              <Route path="/admin/edit-gym/:id" element={<EditAdminGym />} />
              <Route path="/admin/club" element={<AdminClub />} />
              <Route path="/admin/add-club" element={<AddClub />} />
              <Route path="/admin/edit-club/:id" element={<EditClub />} />
              <Route path="/admin/add-city" element={<AdminAddCity />} />
              <Route path="/admin/city" element={<AdminCity />} />
              <Route path="/admin/edit-city/:id" element={<AdminEditCity />} />
              {/* <Route path="/admin/add-place/:id" element={<AdminAddPlace />} />
            <Route path="/admin/view-place/:id" element={<AdminPlace />} /> */}
              <Route
                path="/admin/edit-place/:city/:place"
                element={<EditPlace />}
              />
              <Route path="/admin/service" element={<ServiceRequest />} />
              <Route path="/admin/blog" element={<AdminBlog />} />
              <Route path="/admin/add-blog" element={<AddBlog />} />
              <Route path="/admin/edit-blog/:id" element={<EditBlog />} />
              {/* <Route path="/admin/view-blog/:id" element={<BlogDetails/>} /> */}
              <Route path="/admin/category" element={<AdminCategory />} />
              <Route path="/admin/add-category" element={<AdminAddCategory />} />
              <Route path="/admin/edit-category/:id" element={<EditCategory />} />
              <Route path="/admin/slider" element={<AdminSlider />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/gym-equipments" element={<Gym />} />
            {/* <Route path="/maincard" element={<MainCard />} /> */}
            <Route path="/about" element={<About />} />
            {/* Blog */}
            <Route path="/:url" element={<NewBlogList />} />
            <Route path="/productdetails/:id" element={<Gymdetails />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/academy" element={<Academy />} />
            <Route path="/fitness-club" element={<Franchise />} />
            <Route path="/best-online-personal-training-certification" element={<FitnessTrainer />} />
            <Route path="/product/:url" element={<ProductCategoryPage />} />
            {/* <Route path="/pagination" element={<Pagination />} /> */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
