import React, { useEffect, useState } from "react";
import "./franchise.css";
// import Header from '../../Header/Header';
import Footer from "../../components/footer/Footer";
import TopBanner from "../../components/topBanner/TopBanner";
import one from "../../../assests/franchise/RSF1.jpg";
import { axiosInstance } from "../../../config";
import img from "../../../assests/img/clubbanner.jpg";
import mobimg from "../../../assests/img/mobileclubbanner.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import NewHeader from "../../Header/NewHeader";
import HomeContact from "../../components/Contact/HomeContact";
import Helmet from 'react-helmet';
import PopUp from "../../components/popup/PopUp";
import VideoComp from "./components/VideoComp";
import Map from "../../components/map/Map";
import FranchiseCarousal from "../../components/franchisecarousal/FranchiseCarousal";
import Segmentbox from "./components/segmants/Segmentbox";





const Franchise = () => {
  const [city, setcity] = useState([]);
  const [club, setclub] = useState([]);

  const [isopen, setisopen] = useState(true)

  const [clubcity, setclubcity] = useState("Pune");



  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getcity();
    getclub();
  }, []);

  const getcity = async () => {
    try {
      const { data } = await axiosInstance.get("/api/city");
      setcity(data.data);
      // console.log("dd", data)
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const getclub = async () => {
    try {
      const { data } = await axiosInstance.get("/api/club");
      // console.log("ss", data)
      setclub(data.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const clientdata = [
    {
      id: 1,
      Name: "BSF Border Security Force",
      imgurl: require(`../../../assests/clients/1.png`),
    },
    {
      id: 2,
      Name: "Indian Military Academy, Dehradun",
      imgurl: require(`../../../assests/clients/2.png`),
    },
    {
      id: 3,
      Name: "NSG National Security Guard",
      imgurl: require(`../../../assests/clients/3.png`),
    },
    {
      id: 4,
      Name: "Indian Air Force",
      imgurl: require(`../../../assests/clients/4.png`),
    },
    {
      id: 5,
      Name: "MP Birla Group",
      imgurl: require(`../../../assests/clients/5.png`),
    },
    {
      id: 6,
      Name: "Madhya Pradesh Police",
      imgurl: require(`../../../assests/clients/6.png`),
    },
    {
      id: 7,
      Name: "DRDO",
      imgurl: require(`../../../assests/clients/7.png`),
    },
    {
      id: 8,
      Name: "ISRO",
      imgurl: require(`../../../assests/clients/8.png`),
    },
    {
      id: 9,
      Name: "Honda",
      imgurl: require(`../../../assests/clients/9.png`),
    },
    {
      id: 10,
      Name: "Lanco",
      imgurl: require(`../../../assests/clients/10.png`),
    },
    {
      id: 11,
      Name: "NTPC",
      imgurl: require(`../../../assests/clients/11.png`),
    },
    {
      id: 12,
      Name: "Uttarakhand State",
      imgurl: require(`../../../assests/clients/12.png`),
    },
    {
      id: 13,
      Name: "National Institute of Technology, Srinagar",
      imgurl: require(`../../../assests/clients/13.png`),
    },
    {
      id: 14,
      Name: "Indian Institute of Tourism & Travel Management",
      imgurl: require(`../../../assests/clients/14.png`),
    },
    {
      id: 15,
      Name: " Nationa Institue of Science and Educational Research",
      imgurl: require(`../../../assests/clients/15.png`),
    },
  ];


  const segentdata = [{
    heading: "Bronze Segment",
    subheading1: "Investment Amount 15 lacs",
    subheading: "Area Required 2000 sq ft"
  }, {
    heading: "Silver Segment",
    subheading1: "Investment Amount 25 lacs",
    subheading: "Area Required 2000-2500 sq ft"

  }, {
    heading: "Gold Segment",
    subheading1: "Investment Amount 40 lacs",
    subheading: "Area Required 3500-4000 sq ft"

  }, {
    heading: "Platinum Segment",
    subheading1: "Investment Amount 60 lacs",
    subheading: "Area Required 4500-5000 sq ft"
  }]

  const franchisedata = [
    {
      Id: 1,
      heading: "Great ROI",
      belowheading:
        "Highly optimised capital investments with an expected ROI of 25-30%",
      imgurl: require("../../../assests/franchise/1.png"),
    },
    {
      Id: 2,
      heading: "Best-in-class Trainers",
      belowheading:
        "Top qualities trainers are hired, trained and deployed.",
      imgurl: require("../../../assests/franchise/2.png"),
    },
    {
      Id: 3,
      heading: "RSF Gym Marketing Strength",
      belowheading:
        "RSF Demand generation capabilities through digital marketing & brand campaigns.",
      imgurl: require("../../../assests/franchise/barbell.png"),
    },
    {
      Id: 4,
      heading: "Highly Digitised Training Product",
      belowheading:
        "Al Trainer- Artificial Intelligence based fitness planner creates customised goal-based workout plans for consumers ",
      imgurl: require("../../../assests/franchise/4.png"),
    },
  ];


  const handleClose = () => {
    setisopen(!isopen);
  }

  // setTimeout(() => {
  //   setisopen(false);
  // }, 10000);


  const testimonialdata = [
    {
      id: 1,
      AuthorName: "Aadi Jain",
      TestimonialContent: "The RSF crew is driven to see the centres succeed, and they bring the same zeal to the centres of its partners. They ensure that, as a partner, I receive all the information, knowledge, and assistance required to successfully manage my centre. Having previously collaborated with other major fitness brands, we have unmatched faith in RSF in future.",
      // location: "RSF Chandigarh",
      imgurl: "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"

    }, {
      id: 2,
      AuthorName: "Chirag",
      TestimonialContent: "I joined RSF at the beginning of my fitness journey and quickly become a super user of their excellent workouts and upbeat atmosphere. And when the RSF franchise was revealed, I moved forward without hesitation and opened my own gym with RSF.",
      // location: "RSF Chandigarh",
      imgurl: "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"

    }, {
      id: 3,
      AuthorName: "Ritu Choudhary",
      TestimonialContent: "Excellent and effective use of technology for remote access and monitoring. The business has been. proactive and nimble despite significant pandemic disruptions and uncertain client behaviour. We appreciate being a member of the RSF family!      ",
      // location: "RSF Chandigarh",
      imgurl: "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
    }
    // }, {
    //   id: 4,
    //   AuthorName: "Arpit Raghav",
    //   TestimonialContent: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempore, impedit molestias perspiciatis fugit sapiente vitae, molestiae excepturi reiciendis, illo in tempora nemo voluptates ut. Officiis eius tenetur fugit minima facere ad ipsa a illum! Quos nisi quo veritatis?",
    //   location: "RSF Chandigarh",
    //   imgurl: "https://uploads-ssl.webflow.com/5f5145eb46974e52ee487611/5fd8787cb232a53d60f9b9b4_cult%20jaipur-p-500.png"

    // }, {
    //   id: 5,
    //   AuthorName: "Arpit Raghav",
    //   TestimonialContent: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempore, impedit molestias perspiciatis fugit sapiente vitae, molestiae excepturi reiciendis, illo in tempora nemo voluptates ut. Officiis eius tenetur fugit minima facere ad ipsa a illum! Quos nisi quo veritatis?",
    //   location: "RSF Chandigarh",
    //   imgurl: "https://uploads-ssl.webflow.com/5f5145eb46974e52ee487611/5fd8787cb232a53d60f9b9b4_cult%20jaipur-p-500.png"

    // }, {
    //   id: 6,
    //   AuthorName: "Arpit Raghav",
    //   TestimonialContent: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempore, impedit molestias perspiciatis fugit sapiente vitae, molestiae excepturi reiciendis, illo in tempora nemo voluptates ut. Officiis eius tenetur fugit minima facere ad ipsa a illum! Quos nisi quo veritatis?",
    //   location: "RSF Chandigarh",
    //   imgurl: "https://uploads-ssl.webflow.com/5f5145eb46974e52ee487611/5fd8787cb232a53d60f9b9b4_cult%20jaipur-p-500.png"

    // }
  ]


  const [name, setName] = useState();
  const [phone, setphone] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const { data } = await axiosInstance.post("/api/service", {
        "Name": `${name} `,
        "Email": email,
        "EnquiryFor": "Contact Us",
        // "EquipmentName":"63468c5276049734e666774c",
        "MobNumber": phone,
        "Message": message
      });
      if (data.success) {
        alert(data.message)
      }


    } catch (error) {
      alert(error.response.data.message)

    }
    // alert("form submitted Successfully")


    // axios.get(`http://api.digisidekick.com/api/Mail?Email=aarjav@digisidekick.com&Firstname=${name}&Lastname= ${lastname}&Number=${phone}&Usermial=${email}&UserMessage=${message}&other=null`,).then((res) => {
    //     console.log(res.data)
    // }).catch((error) => {
    //     console.log(error)
    // });


    // setName("");
    // setLastName("");
    // setphone('');
    // setEmail("")
    // setMessage("");
    // navigate("/thank-you")

  }

  return (
    <>

      {
        isopen && <PopUp name="Franchise" handleClose={handleClose} />
      }


      <Helmet>
        <title>Royal Sport n Fitness | Franchise</title>
        <meta name="keywords" content="gym ,equipments,franchise" />
        <meta name="description" content="Royal Sports N Fitness" />
      </Helmet>


      <NewHeader />

      <VideoComp />

      {/* <TopBanner name="RSF Fitness Club" img={img} mobimg={mobimg} /> */}
      <section className="py-5">
        <div className="container">
          <div className="row py-5 px-5">
            <div className="col-md-6 d-flex align-items-streatch">
              <img src={require("../../../assests/franchise/1.webp")} alt="" className="img-fluid " />
              {/* <img src="https://uploads-ssl.webflow.com/5f5145eb46974e52ee487611/5f7aa530a00f940cb4a06efd_cultindoors-p-500.jpeg" alt="" className="img-fluid " /> */}
            </div>
            <div className="col-md-6 text-start text-dark">
              <h1 className="franchiseheadingtext ">
                <b style={{ color: "#EE393E" }}>Open your own<span style={{ color: "#000" }}> RSF</span> </b><br />
                <b>Fitness Club</b>
              </h1>
              <p className="franchiseparagraphtext">
                Introducing the RSF franchise program. Join the revolution.
              </p>

              <a className="btn btn-primary me-3 mt-lg-3 mb-lg-0 mb-3 " href="https://royalsportsnfitness.com/rsf_fitness_brochure.pdf" target="_blank" rel="noreferrer">
                Download brochure
              </a>
              <a href="#form" class="btn btn-outline-danger mt-lg-3">
                Partner With us
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Why partner with RSF */}

      <section className="bg-danger ">
        <div className="container py-5 ">
          <h1 className="text-center py-5 text-white ">
            <b>Why partner with RSF ?</b>
          </h1>
          {/* <div className="row">
            <div className="col-md-3 d-flex align-items-streatch ">
              <img src={require("../../../assests/franchise/s1.png")} alt="" className="img-fluid" style={{height:"100%"}} />
            </div>
            <div className="col-md-9"> */}
          <div className="row px-5 ">
            {franchisedata.map((p) => (
              <>
                <div
                  className="col-md-6 text-center text-lg-start p-4"
                  key={p.Id}
                >
                  <div className="row">
                    <div className="col-md-2 text-center my-auto">
                      <img src={p.imgurl} alt="" loading="lazy" className="" />
                    </div>
                    <div className="col-md-10">
                      <p className="franchiseparagraphtext">
                        <b style={{ color: "#000" }}>
                          {p.heading}
                        </b>
                        <br />
                        <span style={{ color: "#fff", fontSize: "18px" }}>{p.belowheading}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>

          {/* </div>
          </div> */}

          <div className="row justify-content-center py-5">
            <div className="col-md-10 text-center">
              <a href="#form" className="franchisebtn btn w-100">Partner With US</a>
            </div>
          </div>
        </div>
        <div className="container pb-5">
          <div className="row pb-5">
            <div className="col-md-4 text-white">
              <h1 className="text-white">
                <b>Starting a new gym?</b>
              </h1>
              <p>
                Partner with RSF and get that competitive advantage from our
                expertise on best practices, equipment vendors, training academy
                and marketing machinery
              </p>
              <a className="btn btn-primary me-3 " href="https://royalsportsnfitness.com/rsf_fitness_brochure.pdf" target="_blank" rel="noreferrer" >
                Download brochure
              </a>
              <br />
              <a class="btn mt-1 btn btn-outline-light" href="#form"  >
                Partner With us
              </a>
            </div>
            <div className="col-md-8">
              <img
                src={require("../../../assests/franchise/sec1.webp")}
                // src={require("../../../assests/franchise/1.webp")}
                alt=""
                className="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
        </div>

      </section>

      {/* End of Why Choose us */}

      {/* Our Services */}
      <section id="why-us" class="why-us events py-5">
        <div class="container">
          <div class="d-flex ">
            <div class="section-title text-start col-lg-12 text-muted">
              <h1 className="text-center ">
                <b style={{ color: "#EE393E" }}> Our</b> <b> Fitness Clubs</b>
              </h1>
              <p className="text-center">
                The RSF Franchise segment helps aspiring Gym trainers and
                businessmen open their own gym with RSF’s name and become a part
                of one of the top brands in the Indian fitness industry.
              </p>
            </div>
          </div>
          <div
            className="d-flex flex-nowrap  m-lg-2  text-center "
            style={{ overflowX: "auto" }}
          >
            {city &&
              city.map((p) => (
                <>
                  <button
                    className={
                      clubcity === p.name
                        ? "col-md-2 col-5  bg-danger text-white m-lg-3 m-1  p-2 rounded"
                        : "col-md-2  bg-dark text-white m-lg-3 col-5 m-1  p-lg-2 rounded"
                    }
                    onClick={() => setclubcity(p.name)}
                  >
                    {p.name}
                  </button>
                </>
              ))}

            {/* <button className="col-md-2 col-4 bg-dark text-white m-lg-3  p-2 rounded" onClick={() => { setclubcity("Aerial Entries") }}>
                            Aerial Entries
                        </button>

                        <button className="col-md-2 col-4 bg-dark text-white m-lg-3  p-2 rounded" onClick={() => { setclubcity("Aerial Acts") }}>
                            Aerial Acts
                        </button>
                        <button className="col-md-2 col-4 bg-dark text-white m-lg-3  p-2 rounded" onClick={() => { setclubcity("Adventure") }}>
                            Adventure
                        </button >

                        <button className="col-md-2 col-4 bg-dark text-white m-lg-3   p-2 rounded" onClick={() => { setclubcity("Rig Tech") }}>
                            Rig Tech
                        </button> */}
          </div>

          <div class="row">

            {club &&
              club
                .filter((s) => s.City.name === clubcity)
                .map((c, i) => (
                  <>
                    <div class="col-md-4 mt-4 mt-lg-0 ">
                      {/* <Link to={`/singleclub/${c.clubName}`}> */}
                      <div
                        class="card-wrapper rounded-bottom"
                        style={{ backgroundColor: "#D83133" }}
                      >
                        <Carousel
                          autoPlay={true}
                          showThumbs={false}
                          showStatus={false}
                          infiniteLoop={true}
                        >
                          {c.ClubImage.map((p) => (
                            <div>
                              <img
                                src={`${process.env.REACT_APP_URL}/images/club/${p}`} alt="club"
                              />
                            </div>
                          ))}
                        </Carousel>

                        <figure class="p-3  ">
                          {/* <img src={`${process.env.REACT_APP_IMGURL}/images/club/${c?.clubImage}`} className="img-fluid w-100" /> */}
                          <figcaption>
                            <a href="/#" className="text-white">
                              <b>{c?.clubName} </b>
                            </a>{" "}
                          </figcaption>
                          <figcaption>
                            <a href="/#" className="text-white">
                              {c?.ShortDesc}{" "}
                            </a>{" "}
                          </figcaption>
                        </figure>
                      </div>
                      {/* </Link> */}
                    </div>
                  </>
                ))}
          </div>


          {/* Segment Section Design */}


          <div>
            <div class="row  py-lg-3">
              <h1 className="text-center py-4 ">
                <b style={{ color: "#EE393E" }}> Segments</b> <b> Available </b>
              </h1>
              {segentdata.map((c, i) => {
                return (
                  <>
                    <Segmentbox heading={c.heading} subheading={c.subheading} subheading1={c.subheading1} key={c.id} id={i + 1} />
                  </>
                );
              })}
            </div>
          </div>








        </div>
      </section>
      {/* Our Services */}



      {/* <section className="bg-danger py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-white">
              <h1 className="text-white">
                <b>Starting a new gym?</b>
              </h1>
              <p>
                Partner with RSF and get that competitive advantage from our
                expertise on best practices, equipment vendors, training academy
                and marketing machinery
              </p>
              <a className="btn btn-primary me-3 " href="https://royalsportsnfitness.com/rsf_fitness_brochure.pdf" target="_blank" rel="noreferrer" >
                Download brochure
              </a>
              <br />
              <a class="btn mt-1 btn btn-outline-light" href="#form" >
                Partner With us
              </a>
            </div>
            <div className="col-md-8">
              <img
                src={require("../../../assests/franchise/RSF3.jpg")}
                alt=""
                className="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section> */}
      <section className="bg-danger py-5" id="form">
        <div className="container">
          <div className="row">
            <div className="col-md-8 text-white p-2">
              <h1 className="text-dark fw-bold py-2">Interested in becoming a RSF partner ?</h1>
              <p className="franchiseparagraphtext">Please fill the following form if you are interested in partnering with us</p>
              <div className="form">
                <form>
                  <div class="form-group py-3">
                    <label for="exampleInputEmail1">Name</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" required onChange={(e) => setName(e.target.value)} />
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                  </div>
                  <div class="form-group py-3">
                    <label for="exampleInputPassword1">Email</label>
                    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Enter Email" required onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div class="form-group py-3">
                    <label for="exampleInputPassword1">Contact Number</label>
                    <input type="number" class="form-control" id="exampleInputPassword1" min={0} placeholder="Enter Contact Number" required onChange={(e) => setphone(e.target.value)} />
                  </div>
                  <div class="form-group py-3">
                    <label for="exampleInputPassword1"> Message</label>
                    <textarea type="number" class="form-control" id="exampleInputPassword1" min={0} placeholder="Enter Message" required onChange={(e) => setMessage(e.target.value)} />
                  </div>

                  <div className="row justify-content-center py-3">
                    <div className="col-md-12 text-center">
                      <button className="franchisebtn btn w-100" onClick={handleSubmit}>Submit</button>
                    </div>
                  </div>
                  {/* <button type="submit" class="btn btn-phandleSubmitrimary">Submit</button> */}
                </form>
              </div>
            </div>
            <div className="col-md-4 p-2 text-white">
              <span>OUR OFFICE</span>
              <hr />
              <p className="franchiseparagraphtext text-dark">Gali No. 6, Sai Puram Ind. Area, Delhi Rd, Meerut, Uttar Pradesh</p>

              <Map />
              <div className="py-3">
                <span className="">CONTACT</span>
                <hr />
              </div>
              <div className="">
                <span>RSF Franchise</span><br />
                <span>franchise@royalsportsnfitness.com</span>
              </div>
              {/* <div className="py-2">
                <span>Cult Pass Partnership</span>
                <span>cultpasspartnerships@curefit.com</span>
              </div> */}





            </div>
          </div>
        </div>

      </section>





      <section className="py-5 ">
        <div className="container">
          <h1 className="text-center py-5 ">
            <b>
              <span style={{ color: "#DC3545" }}>RSF</span> Presence
            </b>
          </h1>
          <div className="row text-center">
            <div className="col-md-3 col-6 ">
              <h1 className="franchiseheadingtext">
                <b style={{ color: "#DC3545" }}>20+</b>
              </h1>
              <br />
              <h3>Cities</h3>
            </div>
            <div className="col-md-3 col-6">
              <h1 className="franchiseheadingtext">
                <b style={{ color: "#DC3545" }}>14+</b>
              </h1>
              <br />
              <h3>Year of experience</h3>
            </div>

            <div className="col-md-3 col-6">
              <h1 className="franchiseheadingtext">
                <b style={{ color: "#DC3545" }}>200+</b>
              </h1>
              <br />
              <h3>Gyms</h3>
            </div>
            <div className="col-md-3 col-6">
              <h1 className="franchiseheadingtext">
                <b style={{ color: "#DC3545" }}>1000+</b>
              </h1>
              <br />
              <h3>Clients</h3>
            </div>
          </div>
          <div className="row text-center">
            <h1 className="text-center py-3 ">
              <b>
                <span style={{ color: "#DC3545" }}>INDIA</span>
              </b>
            </h1>
            <p className="franchiseparagraphtext text-dark">Bangalore | Assam | Amritsar | Meerut | Delhi | Jammu | Kolkata | Mangalore | Gurugram | Jaipur | Ahmedabad | Gorakhpur | Hyderabad | Chandigarh | Pune | Ghaziabad | Dehradun | Ludhiana | Indore | Lucknow </p>



          </div>
          <div className="row justify-content-center py-3">
            <div className="col-md-12 text-center">
              <a href="#form" className="franchisebtn btn ">Partner With Us</a>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section className='p-3'>
        <h1 className='text-center '><b style={{ color: '#EE393E' }}> Our</b> <b>Clients</b></h1>
        <div class="clientslider">
          <div class="slide-track">
            {
              clientdata.map((p) => (
                <>
                  <div class="clientslide">
                    <img src={p.imgurl} alt="" className='img-fluid' />
                  </div>
                </>
              ))
            }

          </div>
        </div>
      </section> */}

      <section className="py-5 bg-danger">
        <div className="container">
          <div className="row justify-content-center">
            <h1 className="text-center py-3 text-white"><b>State of the art facilities</b></h1>
            <div className="col-md-12 pb-5">

              <FranchiseCarousal />
              <div className="row justify-content-center pt-3">
                <div className="col-md-12 text-center">
                  <a href="#form" className="franchisebtn btn w-100">Partner With Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">

          <div className="row">
            {
              testimonialdata && testimonialdata.map((p) => (
                <CarousalItems key={p.id} name={p.AuthorName} location={p.location} content={p.TestimonialContent} imgurl={p.imgurl} />
              ))
            }


          </div>

        </div>
      </section>

      {/* New Section */}
      <section className="bg-danger">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-md-4 text-white">
              <h1 className="text-white">
                <b>Be Unstoppable</b>
              </h1>
              <p>
                Partner with RSF and get that competitive advantage from our
                expertise on best practices, equipment vendors, training academy
                and marketing machinery
              </p>
              <a className="btn btn-primary me-3 " href="https://royalsportsnfitness.com/rsf_fitness_brochure.pdf" target="_blank" rel="noreferrer" >
                Download brochure
              </a>
              <br />
              <a class="btn mt-1 btn btn-outline-light" href="#form" >
                Partner With us
              </a>
            </div>
            <div className="col-md-8">
              <img
                src={require("../../../assests/franchise/ss.webp")}
                // src="https://uploads-ssl.webflow.com/5f5145eb46974e52ee487611/601ba6f2049e56f959bdae28_fitnessfirstcenter.png"
                alt=""
                className="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>




      <>
        <div class="container py-5 ">
          <div class="text-center ">
            <h1 class="#434342">
              <b style={{ color: "#EE393E" }}>Our </b> <b>Clients</b>
            </h1>
            {/* <h6>
                <b>While you focus on your business, we take care of the rest. Just come in and get started!</b>
            </h6> */}
          </div>

          <div class="row justify-content-center align-items-center py-lg-3">
            {clientdata.map((c) => {
              return (
                <>
                  <div class="col-lg-2 col-md-2 col-4  m-lg-2 m-0" key={c?.Id}>
                    <div class=" text-center m-1 box p-2 m-2 ">
                      {/* <i class="mb-4 fa-solid fa-pen-ruler"></i> */}
                      <img
                        src={c.imgurl}
                        alt=""
                        className="img-fluid p-1"
                        width={"100px"}
                      />
                      {/* <p><b>{c?.Name}</b></p> */}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>

      {/* <section style={{ backgrund: "#CCCCCC" }} id="form">
        <ContactComponent name="Franchise" />
      </section> */}
      {/* <section>
        <HomeContact name="Franchise" />
      </section> */}

      <Footer />
    </>
  );
};




const CarousalItems = ({ name, location, content, imgurl }) => {
  return (
    <>
      <div className="col-md-4 p-3 d-flex align-items-streatch">
        <div className=" bg-danger rounded">
          <div className="row p-3">
            <div className="col-md-3">
              <img src={imgurl} alt="" className="img-fluid profile-picture" />
            </div>
            <div className="col-md-9  ">
              <h6 className="text-white"><b>{name}</b></h6>
              <h5 className="text-white">{location}</h5>
              <p className="text-white">{content}</p>
            </div>

          </div>

        </div>
      </div>

    </>
  )

}

export default Franchise;
