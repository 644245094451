import React, { useEffect, useState } from 'react';
import "./academy.css";
// import Header from '../../Header/Header';
import Footer from '../../components/footer/Footer';
import TopBanner from '../../components/topBanner/TopBanner';
import Affiliate from '../../components/affiliate/Affiliate';
import one from "../../../assests/academy/fit1.png"
import two from "../../../assests/academy/fitness2.jpeg"
import three from "../../../assests/academy/fitness3.jpg"
import img from "../../../assests/img/academybanner.jpg"
import mobimg from "../../../assests/img/mobilefitnessbanner.jpg";
import NewHeader from '../../Header/NewHeader';
import HomeContact from '../../components/Contact/HomeContact';
import Helmet from 'react-helmet';
import PopUp from '../../components/popup/PopUp';
import { Link } from 'react-router-dom';







const Academy = () => {
    const [isopen, setisopen] = useState(true)


    const handleClose = () => {
        setisopen(!isopen);
    }

    // setTimeout(() => {
    //     setisopen(false);
    // }, 10000);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [])

    const clientdata = [{
        id: 1,
        Name: "BSF Border Security Force",
        imgurl: require(`../../../assests/clients/1.png`)
    }, {
        id: 2,
        Name: "Indian Military Academy, Dehradun",
        imgurl: require(`../../../assests/clients/2.png`)
    },
    {
        id: 3,
        Name: "NSG National Security Guard",
        imgurl: require(`../../../assests/clients/3.png`)
    }, {
        id: 4,
        Name: "Indian Air Force",
        imgurl: require(`../../../assests/clients/4.png`)
    },
    {
        id: 5,
        Name: "MP Birla Group",
        imgurl: require(`../../../assests/clients/5.png`)
    },
    {
        id: 6,
        Name: "Madhya Pradesh Police",
        imgurl: require(`../../../assests/clients/6.png`)
    },
    {
        id: 7,
        Name: "DRDO",
        imgurl: require(`../../../assests/clients/7.png`)
    },
    {
        id: 8,
        Name: "ISRO",
        imgurl: require(`../../../assests/clients/8.png`)
    },
    {
        id: 9,
        Name: "Honda",
        imgurl: require(`../../../assests/clients/9.png`)
    },
    {
        id: 10,
        Name: "Lanco",
        imgurl: require(`../../../assests/clients/10.png`)
    },
    {
        id: 11,
        Name: "NTPC",
        imgurl: require(`../../../assests/clients/11.png`)
    },
    {
        id: 12,
        Name: "Uttarakhand State",
        imgurl: require(`../../../assests/clients/12.png`)
    },
    {
        id: 13,
        Name: "National Institute of Technology, Srinagar",
        imgurl: require(`../../../assests/clients/13.png`)
    },
    {
        id: 14,
        Name: "Indian Institute of Tourism & Travel Management",
        imgurl: require(`../../../assests/clients/14.png`)
    },
    {
        id: 15,
        Name: " Nationa Institue of Science and Educational Research",
        imgurl: require(`../../../assests/clients/15.png`)
    },
    ]




    return (
        <>
            {
                isopen && <PopUp name="Academy" handleClose={handleClose} />
            }



            <Helmet>
                <title>Royal Sport n Fitness | Academy</title>
                <meta name="keywords" content="Certified gym trainer course ,Personal training certification,Online personal training certification,Best personal training certification,RSF FITNESS ACADEMY" />
                <meta name="description" content="Royal Sports N Fitness | Academy" />
            </Helmet>
            {/* <Header /> */}
            <NewHeader />
            <TopBanner name="RSF Academy" img={img} mobimg={mobimg} />
            <section className=''>
                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-4 d-flex align-items-streatch">
                            {/* <img src="https://royalsportsnfitness.newswise.in/static/media/anoop.5a933668019ac50790b6.jpg" alt="" className='img-fluid ' /> */}
                            <img src={one} alt="" className='img-fluid ' loading="lazy" />

                        </div>
                        <div className="col-md-8 text-dark">
                            <h1 className=''><b style={{ color: '#EE393E' }}>About </b> <b>Fitness Academy</b></h1>
                            <p >
                                Introducing RSF Fitness Academy - a groundbreaking initiative by RSF, aimed at training and educating Fitness Trainers under the guidance of Professional Coaches and esteemed doctors.
                                Our goal is to provide the best knowledge in the field through workshops, live sessions, and insights from renowned fitness celebrities like Thakur Anoop Singh, our brand ambassador.
                                <br /><br />
                                As one of the most reputable and effective fitness training institutions, RSF offers Certified and Diploma programs in various subject areas. Our comprehensive curriculum ensures students receive wholesome, thorough, and scientifically curated knowledge, keeping them ahead of the competition.
                            </p>
                            <p><b>Join RSF Fitness Academy and elevate your fitness training career to new heights.</b></p>
                            <a href="#form" className='btn btn-danger'>Contact US</a>

                            {/* <button className='btn btn-danger'>Contact US</button> */}
                        </div>

                    </div>
                </div>

            </section>

            <Affiliate />
            <div className='bg-danger'>
                <div className="container py-2" >
                    <div className="row">
                        <div className="col-md-4 col-12 border p-1 align-self-center" >
                            {/* <img src="https://www.23dcarchitects.com/wp-content/uploads/Ar.Shiv-Dada-scaled.jpg" alt="" className='img-fluid' /> */}
                            <img src={require("../../../assests/academy/founder.webp")} alt="" className='img-fluid' loading='lazy' />
                        </div>
                        <div className="col-md-8 text-white py-5">
                            {/* <p style={{ fontSize: "26px" }}><b>Founder’s View</b></p> */}
                            <h2 className='text-white'><b>Founder’s View</b></h2>
                            <p>There is just one fitness school to think about if you want to become a professional personal trainer or nutritionist: <b>RSF FITNESS ACADEMY</b>. RSF has constantly contributed to the development and perfection of the fitness industry.<br /><br />
                                Students from across the country can join the program and can learn from anywhere. The classes are hybrid including offline and online training.<br /><br />
                                The practical sessions for the Diploma in Personal Training Courses are held in a fully equipped and operational gym.<br /><br />
                                With the assistance of Thakur Anoop Singh and many other Celebrity Gym trainers and educators, students can complete the course and obtain certification in physical training and nutrition with 100% placement assistance.<br /><br />
                                <b>Founder and CEO</b><br />
                                <b>Puneet Jain</b>


                            </p>

                        </div>




                        {/* <hr style={{ height: "2px", color: "white" }} className="py-2 mt-2 mb-2" /> */}
                        <div className="col-md-8 text-white">
                            <h3 style={{ fontSize: "26px", color: "white" }}><b>Classes with the renowned Film and TV Actor Thakur Anoop Singh</b></h3>
                            <p>Thakur Anoop Singh is an Indian actor who primarily appears in Telugu films. He played Dhritarashtra in the 2013 TV series Mahabharat. <br /><br />
                                In 2015, he won multiple medals at championships around the world<br /><br />
                                <b> A Gold Medal in a bodybuilding contest in Bangkok, Thailand</b><br />
                                <b>A Bronze Medal at 49th Asian Championship, Uzbekistan</b><br />
                                <b>A Silver Medal at Fit Factor - Mr India</b><br /><br />
                                Thakur Anoop Singh is a Brand Ambassador of RSF and he himself will be training Top Students in each and every class of the course.<br /><br />
                                Multiple workshops are also organized throughout the course term that is organized by famous celebrity trainers of the industry.<br /><br />
                            </p>

                        </div>
                        <div className="col-md-4 col-12 border p-1 align-self-center" >
                            {/* <img src="https://royalsportsnfitness.newswise.in/static/media/anoop.5a933668019ac50790b6.jpg" alt="" className='img-fluid' /> */}
                            <img src={require("../../../assests/img/anoop.jpg")} alt="" className='img-fluid' loading='lazy' />
                        </div>
                    </div>
                </div>

            </div>



            {/* Our Clients */}

            {/* <section>
                <div className="container ">
                    <h1 className='text-center py-4'><b style={{ color: '#EE393E' }}> Our</b> <b>Clients</b></h1>
                    <div className="row ">
                        {
                            clientdata.map((p) => (
                                <>
                                    <div className="col-md-3 " >
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                                            <img src={p.imgurl} alt="" className='img-fluid' />

                                            <h6 className='p-3 text-center'>{p.Name}</h6>

                                        </div>


                                    </div>
                                </>
                            ))
                        }

                    </div>
                </div>
            </section> */}


            {/* <section className='p-3'>
                <h1 className='text-center '><b style={{ color: '#EE393E' }}> Our</b> <b>Clients</b></h1>
                <div class="clientslider">
                    <div class="slide-track">
                        {
                            clientdata.map((p) => (
                                <>
                                    <div class="clientslide">
                                        <img src={p.imgurl} alt="" className='img-fluid' />
                                    </div>
                                </>
                            ))
                        }

                    </div>
                </div>
            </section> */}
            <>
                <div class="container py-4 pb-4 ">
                    <div class="text-center ">
                        <h1 class="#434342">
                            <b style={{ color: "#EE393E" }}>Our </b> <b>Clients</b>
                        </h1>
                        {/* <h6>
                <b>While you focus on your business, we take care of the rest. Just come in and get started!</b>
            </h6> */}
                    </div>

                    <div class="row justify-content-center align-items-center py-lg-3">
                        {clientdata.map((c) => {
                            return (
                                <>
                                    <div class="col-lg-2 col-md-2 col-3  m-2" key={c?.Id}>
                                        <div class=" text-center m-1 box p-2 m-2 ">
                                            {/* <i class="mb-4 fa-solid fa-pen-ruler"></i> */}
                                            <img
                                                src={c.imgurl}
                                                alt=""
                                                className="img-fluid p-1"
                                                width={"100px"}
                                            />
                                            {/* <p><b>{c?.Name}</b></p> */}
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </>
            {/* Course */}

            <section className='bg-danger py-3'>
                <h1 className='text-center py-4'><b className='text-white'> Our </b> <b>Courses</b></h1>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 my-3">
                            <div className="row bg-white p-3">

                                <div className="col-md-3 p-0 m-0">
                                    <img src={two} alt="" className='img-fluid' />
                                </div>
                                <div className="col-md-9 text-dark">
                                    <h5 ><b>Certified Physical Trainer</b></h5>
                                    <p>
                                        The course covers all the essential skills needed to deliver outstanding consultation to clients. The learning journey starts with a comprehensive study of human anatomy and exercise physiology, ensuring a deep understanding of joints, ligaments, and internal organs.
                                        <br /><br />

                                        <Link to="/best-online-personal-training-certification" className='btn btn-danger'>Know More</Link>
                                    </p>



                                </div>


                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="row bg-white p-3">

                                <div className="col-md-3 p-0 m-0">
                                    {/* <img src="https://royalsportsnfitness.newswise.in/static/media/anoop.5a933668019ac50790b6.jpg" alt="" className='img-fluid ' /> */}
                                    <img src={three} alt="" className='img-fluid ' />
                                </div>
                                <div className="col-md-9 text-dark d-flex align-items-streatch">
                                    <div>
                                        <h5><b>Diet And Nutrition: A Beginners Course</b></h5>
                                        <p>
                                            A comprehensive introductory course covering the fundamentals of diet and nutrition, providing essential knowledge and skills for trainers to understand the impact of food on health and wellness.<br /><br />
                                            {/* <b>₹ 400</b><br /> */}
                                            <Link to="/best-online-personal-training-certification" className='btn btn-danger'>Know More</Link>
                                        </p>

                                    </div>

                                </div>


                            </div>


                        </div>
                    </div>
                </div>









              
            </section>



            {/* <section style={{ background: "#CCCCCC" }} id="form">
                <ContactComponent name="Academy" />
            </section> */}

            <section id="form">

                <HomeContact name="Academy" />

            </section>






            <Footer />

        </>
    )
}

export default Academy