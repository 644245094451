import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux';
import {
    persistReducer, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import sliderSlice from "./slice/sliderSlice";


const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['user']
};



const reducers = combineReducers({
    slider:sliderSlice
}); 

const persistedReducer = persistReducer(persistConfig, reducers);



export default configureStore({
    reducer: persistedReducer,

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    // Hide Redux Devloper Tool on Production
    // devTools: false,
})
