import React, { useEffect, useState } from 'react'
import "./newHeader.css";
import "./header.css";
import { Link, NavLink } from 'react-router-dom';
import { axiosInstance } from '../../config';
import { FaDownload } from 'react-icons/fa';




const NewHeader = () => {

    const [category, setcategory] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0)
        getequipmentcategory()
    }, [])

    const getequipmentcategory = async () => {
        try {
            const { data } = await axiosInstance.get("api/category");
            setcategory(data.category)
        } catch (error) {
            alert(error.response.data.message)

        }

    }



    const btnn = () => {
        let btn = document.querySelector('.menu_btn');
        let navbar = document.querySelector('.nav_icon');
        navbar.classList.toggle('mobile');
        btn.classList.toggle('fa-times');
    }


    return (
        <div>
            {/* <!-- <Header Start============ --> */}
            <header className='' >
                {/* Strip Design */}
                <div style={{width:"101%",zIndex:"2",color:"#fff",backgroundColor:"#fff"}} className=" position-fixed start-0  top-0  bg-dark">
                    <div className='d-flex justify-content-center align-items-center py-1' id='strip'>
                        <a href="https://royalsportsnfitness.com/rsf_academy.pdf" target="_blank" rel="noreferrer" className='px-lg-2 pe-1'> <p><b>Academy Brochure <FaDownload  /></b></p></a>
                        <a href="https://royalsportsnfitness.com/rsf_fitness_brochure.pdf" target="_blank" rel="noreferrer"className='px-lg-2 ps-1'> <p><b>Franchise Brochure <FaDownload /></b></p></a>
                       {/* <p><b> Download <FaDownload/></b></p> */}

                    </div>
                </div>
                


                <nav className="header_box px-5">

                    <div className="logo mt-0 rounded ">

                        <Link to="/" class=" ">
                            <img src={require("../../assests/img/rsflogo.png")} alt="" className='img-fluid' style={{ maxWidth: "34%", height: "auto" }} />
                        </Link>
                    </div>
                    <div className="nav_icon  ">
                        <li><NavLink to="/" className={({ isActive }) =>
                            isActive ? 'navactive' : 'bg-red-500 font-thin'
                        } end>Home</NavLink ></li>

                        {/* <li class="mydropdown d-lg-block d-none">
                            <a class="service1" id='about_open' href='/#'>
                                Services <HiOutlineChevronDoubleDown />
                            </a>
                            <div class="service-menu" id='service-menu'>

                                <div className="container-fluid">
                                    <div className="text-center text-white py-3">
                                        <h2><b className='text-white'>Services</b></h2>
                                    </div>
                                    <div className="row justify-content-around">
                                        <div className="col-lg-4 text-center">
                                            <Link to='/fitness-club' className="text-center mybtns" id="submenu" style={{ cursor: 'pointer' }} >
                                              
                                                <div class="service-submenu border d-flex justify-content-center align-items-center rounded " style={{ width: '100%', height: '65vh', backgroundSize: 'cover', backgroundImage: `url(${require(`../../assests/academy/3.jpg`)})` }} >
                                                   
                                                </div>

                                            </Link>
                                        </div>
                                        <div className="col-lg-4 text-center">
                                            <Link className="text-center mybtns" style={{ cursor: 'pointer' }} to={`/academy`} >
                                                
                                                <div class="service-submenu border d-flex justify-content-center align-items-center rounded " style={{ width: '100%', height: '65vh', backgroundSize: 'cover', backgroundImage: `url(${require(`../../assests/academy/2.jpg`)})` }} >

                                                   
                                                </div>
                                               
                                            </Link>
                                        </div>
                                        <div className="col-lg-4 text-center">
                                            <Link className="mybtns" style={{ cursor: 'pointer' }} to="/gym-equipments" >
                                               
                                                <div class="service-submenu border d-flex justify-content-center align-items-center rounded " style={{ width: '100%', height: '65vh', backgroundSize: 'cover', backgroundImage: `url(${require(`../../assests/academy/1.jpg`)})` }} >

                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li> */}



                        <li className='equipment-dropdown d-none d-lg-block'>
                            <NavLink to="/gym-equipments" className={({ isActive }) =>
                                isActive ? 'navactive equipment-dropdown' : 'bg-red-500 font-thin'
                            } end>Equipment</NavLink >
                            <div class="dropdown-content">
                                <Link to={`/gym-equipments`}>All Product</Link>
                                {
                                    category && category.map((cat) => (
                                        <>
                                            <Link to={`/product/${cat._id}`}>{cat.name}</Link>
                                        </>
                                    ))
                                }

                            </div>
                        </li>

                        {/* ====================== Mobile Drop */}
                        <a data-toggle="collapse" href="#Products" role="button" class="d-lg-none d-block" aria-expanded="false"
                            aria-controls="collapseExample">
                            Equipment »
                        </a>
                        <div class="collapse d-lg-none " id="Products">
                            <div class="collapse mobiledropdown" id="Products">
                                <Link class="dropdown-item" to={`/gym-equipments`}>All Product</Link>

                                {
                                    category && category.map((cat) => (
                                        <>
                                            <Link class="dropdown-item" to={`/product/${cat._id}`}>{cat.name}</Link>
                                        </>
                                    ))
                                }


                                {/* <Link
                                    class="dropdown-item"
                                    to="/fitness-club"
                                    style={{ color: "#fff", fontSize: "15px" }}
                                >
                                    Fitness Club
                                </Link>
                                <Link
                                    class="dropdown-item"
                                    to="/academy"

                                    style={{ color: "#fff", fontSize: "15px" }}
                                >
                                    Fitness Academy
                                </Link>
                                <Link
                                    class="dropdown-item"
                                    to="/gym-equipments"
                                    style={{ color: "#fff", fontSize: "15px" }}
                                >
                                    Equipments
                                </Link> */}
                            </div>

                        </div>


                        <li><NavLink to="/academy" className={({ isActive }) =>
                            isActive ? 'navactive' : 'bg-red-500 font-thin'
                        } end> Academy</NavLink ></li>
                        <li><NavLink to="/fitness-club" className={({ isActive }) =>
                            isActive ? 'navactive' : 'bg-red-500 font-thin'
                        } end>Franchise/Club</NavLink ></li>
                        <li><NavLink to="/best-online-personal-training-certification" className={({ isActive }) =>
                            isActive ? 'navactive' : 'bg-red-500 font-thin'
                        } end>Fitness Trainer</NavLink ></li>








                        <li><NavLink to="/blog" className={({ isActive }) =>
                            isActive ? 'navactive' : 'bg-red-500 font-thin'
                        } >Blogs</NavLink></li>
                        <li><NavLink to="/about" className={({ isActive }) =>
                            isActive ? 'navactive' : 'bg-red-500 font-thin'
                        }>About Us</NavLink></li>
                        <li><NavLink to="/contact-us" className={({ isActive }) =>
                            isActive ? 'navactive' : 'bg-red-500 font-thin'
                        }>Contact Us</NavLink></li>
                    </div>
                    <i className="menu_btn fas fa-bars text-dark d-lg-none d-block coloradd" onClick={btnn} style={{ Color: `#fff !important` }}></i>
                </nav>
            </header>
            {/* <!-- <Header End============= --> */}

        </div>
    )
}

export default NewHeader