import { createSlice } from "@reduxjs/toolkit";

export const sliderSlice = createSlice({
    name:"slider",
    initialState:{
        slider:[],
        loading:false,
        error:false
    },
    reducers:{
        getsliderdata:(state,action)=>{
            state.loading = true
            state.error = false
        },
        sliderdatasuccess:(state,action)=>{
            state.loading = false
            state.error = false
            state.slider = action.payload
        },
        sliderdatafail:(state,action)=>{
            state.loading = false
            state.error = true
        }
    }

})


export const {getsliderdata,sliderdatasuccess,sliderdatafail} = sliderSlice.actions
export default sliderSlice.reducer