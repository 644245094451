import React, { useState } from 'react'
import { axiosInstance } from '../../../config';
import Footer from '../footer/Footer';
import NewHeader from '../../Header/NewHeader';



const Login = () => {

  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [loader, setloader] = useState(false)



  const handlelogin = async () => {
    // e.preventDefault()
    setloader(true)
    try {

      const { data } = await axiosInstance.post("/api/user/login", {
        "Email": email,
        "Password": password
      }, {
        headers: {
          "token": localStorage.getItem("token")
        }
      })
      // console.log(data, "login data")
      if (data) {
        setloader(false)
        alert("Login Successfully")
        // authentication(true)
        window.location.href = "/admin/gym"
        // setTimeout(() => {
        //   navigate("/admin/gym")
        // }, 200);
        localStorage.setItem("token", data.token)
      }
    } catch (error) {
      setloader(false)
      console.log(error.response.data.message)
    }
  }

  return (
    <>
      <NewHeader />
      {/* <Header /> */}

      <>
        <div className="container-fluid">
          <div className="container " style={{ marginTop: "60px" }}>
            <div className="row  align-items-center   " style={{
              borderRadius: "10px",
              border: "2px solid gray"
            }}>
              <div
                className="col-md-6 rounded"
              // style={{
              //   backgroundImage: `url("https://res.cloudinary.com/talk-private/image/upload/v1667563913/218_1_1_mskc3j.jpg")`,
              //   height: "100%",
              //   backgroundSize: "cover",
              //   objectFit: "contain",
              //   // position:"real"

              // }}
              >

                <img src="https://res.cloudinary.com/talk-private/image/upload/v1667563913/218_1_1_mskc3j.jpg" alt="" className="img-fluid" loading="lazy" />
              </div>
              <div className="col-md-6 text-center ">
                <div>
                  <div className="col-md-12   m-3">

                    <h2 style={{

                      color: "black",
                      fontFamily: "initial",
                      width: "70%",
                      margin: "-1",
                      textAlign: "center",
                    }}>Login Page</h2>
                  </div>
                  {/* <hr/> */}
                  <div className="col-md-12  m-3">
                    <input
                      className="inputbox"
                      type="email"
                      placeholder=" admin123@gmail.com"
                      style={{
                        width: "70%",
                        height: "60%"
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="col-md-12 m-3">
                    <input
                      type="password"
                      className="inputbox"
                      placeholder="  **********"
                      style={{
                        width: "70%",
                        height: "60%"
                      }}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12 m-3">


                    {
                      loader ? <button className='btn' style={{
                        width: "70%",
                        height: "20%",
                        backgroundColor: "#f81010a8",
                      }}
                      >

                        Loading...
                      </button> 
                      : <button className='btn' style={{
                        width: "70%",
                        height: "20%",
                        backgroundColor: "#f81010a8",
                      }}
                        onClick={handlelogin}
                      >

                        Login
                      </button>
                    }



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
      <Footer />
    </>
  )
}

export default Login