import React from 'react';
import "./footer.css";
import { BsFacebook, BsInstagram, BsPinterest } from "react-icons/bs";
import { AiFillYoutube, AiFillTwitterCircle } from "react-icons/ai";
import FixedContact from '../fixedwp/FixedContact';
import { Link } from 'react-router-dom';
import { FaBuilding } from 'react-icons/fa';


const Footer = () => {
  return (
    <>
      <FixedContact />
      <footer class="footer px-md-5 pt-5" role="contentinfo">
        <div class="container">
          <div class="row">
            <div class="col-md-4 mb-4 mb-md-0">
              <Link to="/" class="">
                <img src={require("../../../assests/img/rsflogo.png")} class="img-fluid " alt="" style={{ maxWidth: "56%", height: "auto" }} loading="lazy"/>
              </Link>
              <p className='mt-4'> Established in the year 2007, At Royal Sports and Fitness, we are committed to helping our customers achieve their fitness goals by providing top-quality gym equipment, industry-leading education through our fitness academy, and lucrative franchise opportunities.

              </p>
              <p class="social">
                  <a href="https://www.facebook.com/royalsportsnfitnessIndia" target="_blank"  rel="noreferrer"><span ><BsFacebook /></span></a>
                  <a href="https://www.instagram.com/royalsportnfitness/" target="_blank" rel="noreferrer"><span ><BsInstagram /></span></a>
                  <a href="https://www.youtube.com/@royalsportsnfitness" target="_blank" rel="noreferrer"><span ><AiFillYoutube /></span></a>
                  <a href="https://twitter.com/RSFGymEquipment" target="_blank" rel="noreferrer"><span ><AiFillTwitterCircle /></span></a>
                  <a href="https://tr.pinterest.com/fitnssrsf/" target="_blank" rel="noreferrer"><span ><BsPinterest /></span></a>
                </p>

            </div>
            <div class="col-md-4  text-white ">
              <h6 className='fw-bold text-white'>Address</h6>
              <hr style={{ width: "70px", height: "3px" }} />
              {/* <span> */}
                <p><i class="fas fa-home mr-3">&nbsp;&nbsp;</i>Gali No. 6, Sai Puram Ind. Area, Delhi Rd, Meerut, Uttar Pradesh</p>
                <p><i class="fas fa-envelope mr-3">&nbsp;&nbsp;</i>contact@royalsportsnfitness.com</p>
                <p><i class="fas fa-phone mr-3">&nbsp;&nbsp;</i>+91-7455900612</p>
                

              {/* </span> */}
              <h6 className='fw-bold text-white '>Branch Office</h6>
              <hr style={{ width: "70px", height: "3px" }} />
              <p><i class="fas fa-home mr-3">&nbsp;&nbsp;</i>Plot No. 143, 1st Floor, Kakrola Housing Complex, Opposite Metro Pillar No. 789, Dwarka Mor, New Delhi 110078, Mo. +91-7838149795</p>


            </div>
            <div class="col-md-4 ">
              <h6 className='fw-bold text-white '>Branch Office</h6>
              <hr style={{ width: "120px", height: "3px" }} />
              
              <p><i class="fas fa-home mr-3">&nbsp;&nbsp;</i>Royal sports and fitness J.K. Tower Basement, Pandit Ji Ki Thadi, Near Hotel Manohar Palace, Kalwar Road, Jhotwara, Jaipur, Rajasthan, Mo. +91-9784023688</p>
              <p><i class="fas fa-home mr-3">&nbsp;&nbsp;</i>Shop No 5,   Setu Scarlet Building, Gandhi Nagar Highway, Ahmedabad 382424</p>
              <h6 className='fw-bold text-white '>Fitness Club</h6>
              <hr style={{ width: "120px", height: "3px" }} />
              {/* <p><FaBuilding/>&nbsp;&nbsp;Shop No 5,   Setu Scarlet Building, Gandhi Nagar Highway, Ahmedabad 382424</p> */}
              <p><FaBuilding/>&nbsp;&nbsp;1st Floor Yadav Complex New DP Road, Front of life 360 Society, Rahatani, Pimpri - 17, Pune 411017</p>
              <p><FaBuilding/>&nbsp;&nbsp;SCO No.3 Platinum Square, Ambala Chandigarh Highway, Zirakpur, Chandigarh</p>



            </div>
          </div>

          <div class="row justify-content-center text-center">
            <div class="col-md-7">
              <p class="copyright">&copy; Copyright <a href='https://digisidekick.com/' target="_blank" rel="noreferrer">Digisidekick</a>. All Rights Reserved</p>
              {/* <div class="credits">
            Designed by <a href="#">Shubham</a>
          </div> */}
            </div>
          </div>

        </div>
      </footer>


    </>
  )
}

export default Footer