
export default [
    {
        Id: 1,
        imgsrc: require(`../../../assests/affiliate/rsf1.png`),
        Name:"NSDC"
    },
    {
        Id: 2,
        imgsrc: require(`../../../assests/affiliate/rsf2.png`),
        Name:"SKILL INDIA"
    },
    {
        Id: 3,
        imgsrc: require(`../../../assests/affiliate/rsf3.jpg`),
        Name:"FIT INDIA"
    },
    {
        Id: 4,
        imgsrc: require(`../../../assests/affiliate/rsf4.png`),
        Name:"MSME"
    },
    {
        Id: 5,
        imgsrc: require(`../../../assests/affiliate/rsf5.png`),
        Name:"ISO"
    }
   
];