import React from 'react';
import "./segment.css"
import { AiOutlineDoubleRight } from 'react-icons/ai';

const Segmentbox = ({ heading, subheading, subheading1, id }) => {
    return (
        <>
       
            <div class="col-lg-3 col-md-3 col-12 " >
                <div class=" text-start  segmentbox">
                    
                    <span>{`0${id}`}</span>
                    <h5 className='my-3'><b>{heading}</b></h5>
                    <p><b><span className='my-auto'><AiOutlineDoubleRight size={17}/></span> {subheading}</b></p>
                    <p><b><span className='my-auto'><AiOutlineDoubleRight size={17}/></span>{subheading1}</b></p>

                   
                    
                </div>
            </div>

       
            

        </>
    )
}

export default Segmentbox