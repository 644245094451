import React, { useState } from 'react';
import { axiosInstance } from '../../../config';
import "./popup.css"

const PopUp = ({ name, handleClose }) => {


    const [formdata, setformdata] = useState({
        fullname: "",
        phone: "",
        email: "",
        message: ""
    })

    const { fullname, phone, email, message } = formdata

    const onChange = (e) => {
        setformdata({ ...formdata, [e.target.name]: e.target.value })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axiosInstance.post("/api/service", {
                "Name": fullname,
                "Email": email,
                "EnquiryFor": name,
                // "EquipmentName":"63468c5276049734e666774c",
                "MobNumber": phone,
                "Message": message
            });
            if (data.success) {
                alert(data.message)
            }
        } catch (error) {
            alert(error.response.data.message)

        }

    }


    return (
        <>
            <div id='popup_form'>
                <div className="popup-box">
                    <div className="box">
                        <span className="close-icon" onClick={handleClose}>x</span>
                        <div className="row ">
                            {/* <div className="col-md-6 px-1 d-none d-lg-block ">
                                <div className='bg-img-popup rounded'></div> */}
                            {/* <img src={require("../../../assests/img/rsfpopup.jpg")} alt="popup Contact Us" height={"210px"} className='img-fluid rounded ' loading="lazy" /> */}
                            {/* <img src={require("../../../assests/img/person_1.jpg")} alt="popup Contact Us" height={"210px"} className='img-fluid rounded' loading="lazy" /> */}
                            {/* <img src={require("../../../assests/img/newpopup.png")} alt="popup Contact Us"  className='bg-img-popup  rounded' loading="lazy" /> */}
                            {/* </div> */}
                            {/* <div className="col-md-6"> */}
                            <div class="col-lg-12 col-md-12 col-12 px-0 bg-white rounded" >

                                {/* <img src="https://deltahome.in/assets/img/banner/banner-1.webp" alt="popup Contact Us"  height={155} className='img-fluid rounded ' loading="lazy" /> */}
                                {/* <img src="https://dummyimage.com/1920x1000/000/fff" alt="popup Contact Us"  height={155} className='img-fluid rounded ' loading="lazy" /> */}
                                <img src={require("../../../assests/img/dd.webp")} alt="popup Contact Us" className='img-fluid rounded-top ' />
                                <div className=""  >
                                    <h5 className="text-center mb-0 py-2 fw-bolder "><span className='text-primary'>Contact us</span> with your queries.</h5>
                                    {/* <small>To make your Client’s Event Memorable, Fill out the Form Below.</small> */}
                                    <form class="px-3 pb-3" method="post" onSubmit={onSubmit}>

                                        <div class="form-row my-1">
                                            <div class="col">
                                                <input type="text" className=' form-control px-0 py-2' name="fullname" placeholder='Full Name' onChange={onChange} required />
                                            </div>
                                        </div>
                                        <div class="form-row my-1">
                                            <div class="col">
                                                <input type="text" className='form-control px-0 py-2' name="phone" placeholder='Mob Number' onChange={onChange} required />
                                            </div>
                                        </div>
                                        <div class="form-row my-1">
                                            <div class="col">
                                                <input type="email" className='form-control px-0 py-2' name='email' placeholder='Email' onChange={onChange} required />
                                            </div>
                                        </div>
                                        <div class="form-row my-1">
                                            <div class="col">
                                                <textarea class="form-control px-0 py-2" placeholder="Message" id="floatingTextarea2" onChange={onChange}  ></textarea>
                                                {/* <input type="text" className='form-control' name='message' placeholder=' City' onChange={onChange} required /> */}
                                            </div>
                                        </div>

                                        <input class="btn btn-primary  rounded col-lg-12 col-md-12 col-12 px-5 mt-2" type="submit" value="Submit" id="submit" />
                                    </form>

                                    {/* <!-- <a href="#" class="btn col-lg-12 " style=" background-color:#ED1E22 "><b class="text-white">Submit</b></a> --> */}


                                    {/* </div> */}

                                </div>


                            </div>
                        </div>
                        {/* {content} */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopUp