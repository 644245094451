import React, { useEffect, useState, useRef } from 'react';
import { axiosInstance } from '../../../../config';
// import Header from '../../../Header/Header';
import Footer from '../../footer/Footer';
import "./gym.css";
import MainCard from '../../MainCard/MainCard';
import img from "../../../../assests/img/equipmentbanner.jpg"
import mobimg from "../../../../assests/img/mobilegymbanner.jpg"
import TopBanner from '../../topBanner/TopBanner';
import NewHeader from '../../../Header/NewHeader';
import Helmet from 'react-helmet'
import PopUp from '../../popup/PopUp';
import VideoComp from '../../video/VideoComp';
import HomeContact from '../../Contact/HomeContact';







const Gym = () => {


    const [gym, setgym] = useState([])
    const [page, setpages] = useState(1);
    const [loading, setloading] = useState(false);
    const scorllToRef = useRef();


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
        getgym();
    }, [])

    const [isopen, setisopen] = useState(true)


    const handleClose = () => {
        setisopen(!isopen);
    }



    const getgym = async () => {
        try {
            setloading(true)
            const { data } = await axiosInstance.get("/api/product");
            // console.log("dh", data)
            if (data.success) {
                setgym(data.data)
                setloading(false)
            }

        } catch (error) {
            setloading(false)
            console.log(error.response.data.message)
        }

    }

    const setselecedpage = (page) => {

        if (page < 1 || page > Math.ceil(gym.length / 18)) {
            return
        }

        setpages(page)
        scorllToRef.current.scrollIntoView()

        // window.scrollTo({ top: 0, behavior: "smooth" })

    }

    // setTimeout(() => {
    //     setisopen(false);
    // }, 10000);






    return (
        <>

            {
                isopen && <PopUp name="Machine" handleClose={handleClose} />
            }
            <Helmet>
                <title>Royal Sports n Fitness | Gym</title>
                <meta name="keywords" content="gym ,equipments,franchise" />
                <meta name="description" content="Royal Sports N Fitness" />
            </Helmet>
            <NewHeader />
            <TopBanner name="Equipments" img={img} mobimg={mobimg} />

            {/* <section class="product_banner d-flex justify-content-center align-items-center "><h1 className='text-white'><b>All Products</b></h1></section> */}
            <section className="" >

                <div className="container" ref={scorllToRef}>
                    <div className="">
                        <div className="row pt-5 pb-5" id="down">
                            {
                                loading ? <div className='row d-flex justify-content-center position-relative top-50 mt-5 ' style={{ height: "20vh" }}>
                                    <div className="col-md-8 text center position-absolute top-40 start-50">
                                        <div class="spinner-border text-dark " role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div> : gym?.length === 0 ? <>
                                    <div className="row d-flex justify-content-center mt-5">
                                        <div className="col-md-8 text-center">
                                            <h4>No Product Available</h4>
                                        </div>
                                    </div>
                                </> :
                                    gym && gym?.slice(page * 18 - 18, page * 18).map((pro) => {
                                        return (
                                            <>
                                                <div className="col-md-4 text-lg-start text-center  " key={pro._id}>
                                                    <MainCard pro={pro} />
                                                </div>
                                            </>
                                        )
                                    })
                            }

                        </div>
                    </div>

                    {
                        gym.length > 0 &&
                        <div className="row justify-content-center ">
                            <div className="col-md-12 text-center">
                                <nav aria-label="Page navigation example" style={{ display: "flex", justifyContent: 'center' }}>
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <button class="page-link" aria-label="Previous" onClick={() => setselecedpage(page - 1)}>
                                                <span aria-hidden="true">&laquo;</span>
                                                <span class="sr-only" >Previous</span>
                                            </button>
                                        </li>
                                        {[...Array(Math.ceil(gym.length / 18))].map((_, i) => (
                                            <>
                                                {/* <span key={i} style={{ padding: "15px 20px", border: "2px solid red" }} onClick={() => setselecedpage(i + 1)}>{i + 1}</span> */}
                                                <li key={i} className={page === i + 1 ? "page-item active" : "page-item "}><button class="page-link" onClick={() => setselecedpage(i + 1)}>{i + 1}</button></li>
                                            </>
                                        ))}


                                        {/* {[...Array(gym.length / 18)].map((_, i) => (
                                            <>
                                                <li key={i} className={page === i + 1 ? "page-item active" : "page-item "}><a class="page-link" onClick={() => setselecedpage(i + 1)}>{i + 1}</a></li>
                                            </>
                                        ))} */}



                                        <li class="page-item">
                                            <button class="page-link" aria-label="Next" onClick={() => setselecedpage(page + 1)}>
                                                <span aria-hidden="true">&raquo;</span>
                                                <span class="sr-only" >Next</span>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                    }

                </div>

                <VideoComp />
                {/* <OurAchievements/> */}

                <section id="form">

                    <HomeContact name="Contact" />

                </section>

            </section>
            <Footer />

        </>
    )
}

export default Gym