import React from 'react';
import affiliateData from './affiliateData';
import "./affiliate.css"

const Affiliate = () => {
    return (
        <>
            <div class="container py-5">

                <div class="text-center ">
                    <h1 class="#434342"><b style={{ color: '#EE393E' }}>Affiliate</b> <b>With</b></h1>
                    {/* <h6>
                <b>While you focus on your business, we take care of the rest. Just come in and get started!</b>
            </h6> */}
                </div>


                <div class="row justify-content-center align-items-center py-lg-3">
                    {
                        affiliateData.map((c) => {

                            return (
                                <>
                                    <div class="col-lg-3 col-md-3 col-6" key={c?.Id}>
                                        <div class="mybox text-center p-3">
                                            {/* <i class="mb-4 fa-solid fa-pen-ruler"></i> */}
                                            <img src={c.imgsrc} alt="" className='img-fluid' loading="lazy"/>
                                            <p><b>{c?.Name}</b></p>
                                        </div>
                                    </div>

                                </>
                            )
                        })
                    }


                </div>

            </div>

        </>
    )
}

export default Affiliate