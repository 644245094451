import React from 'react';
import { Link } from 'react-router-dom';
import "./maincard.css";




const MainCard = ({pro}) => {
    return (
        <>
            {/* <div className="row " style={{marginTop:"200px"}}>
                <div className="col-md-6 text-lg-start text-center" > */}
                        <div className="maincard my-lg-5 my-3">
                            <div className="circle">
                            </div>
                            <div className="content">
                                <h2>{pro.ProductName}</h2>
                                {/* <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p> */}
                                <h2>

                                <Link to={`/productdetails/${pro._id}`} className='btn btn-primary tex-center'>Explore</Link>
                                </h2>
                            </div>
                            {/* <img src="https://www.pepsi.com/en-us/uploads/images/can-pzs.png" alt="" /> */}
                            <img src={`${process.env.REACT_APP_URL}/images/Product/${pro?.ProductImage[0]}`} alt="" className='img-fluid' loading="lazy"/>
                        {/* </div>
                </div> */}
            </div>
        </>
    )
}

export default MainCard