import React from 'react'
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css";

const FranchiseCarousal = () => {


    const imagedata = [
        {
            id:1,
             // imgurl:"https://royalsportsnfitness.com/images/Blog/1679059797812_Rsf-banner-(5).jpg"
            imgurl:require("../../../assests/franchise/slider1.png")
        },{
            id:2,
            imgurl:require("../../../assests/franchise/slider2.png")

        },{
            id:3,
            imgurl:require("../../../assests/franchise/slider3.webp")

        }
    ]



    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    };
    return (
        <>
            <Carousel itemClass="p-0 m-0" responsive={responsive} infinite={true} showDots={true} autoPlaySpeed={1000}>
                {imagedata?.map((p, i) => (
                    <>
                        <img src={p.imgurl} alt="" className='img-fluid'/>

                    </>
                ))}

            </Carousel>


        </>
    )
}

export default FranchiseCarousal